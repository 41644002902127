import { faContactCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AboutSEO } from '../components/LangSeo';

function AboutUs() {
    const { t } = useTranslation("global");

    return <>
          <AboutSEO />
    <div className="scroll-smooth w-full overflow-y-scroll overflow-x-hidden bg-[#202020]">

        <div className="m-0 h-[calc(100dvh)] rounded-none snap-center flex flex-col  items-center justify-center p-4 sm:p-8 md:p-12 lg:p-16 xl:px-24 gap-8">          

        <div className='w-full flex flex-row  items-center gap-3'>
        <img className='w-40 h-40 md:h-auto md:w-1/4 hover:scale-110 hover:shadow-3xl transition-transform duration-300 ease-in-out
        hover:shadow-white rounded-lg hover:rotate-2' src='/rochi.webp' />
            <div className='self-center w-full xl:w-1/2'>
                <h2 className="font-sans  text-enoOrange-200 uppercase mb-2 leading-[1] sm:leading-[1] text-2xl sm:text-4xl md:text-3xl">
                Roberto Rocchi
                </h2>
                <p className="font-serif text-white text-xs mt-1 sm:text-base sm:leading-[1.25]">
                {t('about.Rob')} <a className='text-enoOrange-200' href='www.remusic.it'>www.remusic.it</a>
                </p>
            </div>
        </div>

        <div className='w-full flex flex-row-reverse  items-center gap-3 lg:mt-0 xl:-mt-20'>
        <img className='w-40 h-40 md:h-auto md:w-1/4 hover:scale-110 hover:shadow-3xl transition-transform duration-300 ease-in-out
        hover:shadow-white rounded-lg hover:rotate-2' src='/tony.webp' />
        <div className='self-center w-full xl:w-1/2'>
            <h2 className="font-sans  text-enoOrange-200 text-right uppercase mb-2 leading-[1] sm:leading-[1] text-2xl sm:text-4xl md:text-3xl">
            Antonio Sarcinelli Postiglione
            </h2>
            <p className="font-serif text-white text-right text-xs mt-1 sm:text-base sm:leading-[1.25]">
            {t('about.Ton')}
            </p>
        </div>
        
        </div>
        </div>

        {/* About us */}
        <div className="m-0 h-[calc(100dvh)] md:h-[100vh] rounded-none  relative flex flex-col md:flex-row items-center justify-center p-4 sm:p-4 md:p-16 lg:p-24 xl:p-32 gap-8">          

        <div className='w-full lg:w-full xl:w-2/3'>

        <h2 className="font-sans text-enoOrange-200 uppercase leading-[1] sm:leading-[1] text-xl sm:text-2xl md:text-3xl">
        {t('about.tWhy')}
        </h2>
        <p className="font-serif leading-tight text-white text-sm mt-1 sm:text-base sm:leading-[1.25]">
        {t('about.why')}
        </p>
        <p className="font-serif leading-tight text-white text-sm sm:text-base sm:leading-[1.25] mt-4">
        {t('about.why1')}
        </p>
        </div>
        <div className='w-full md:w-1/3 '>
        <h3 className="font-sans text-white text-left md:text-left left-0 -translate-y-3/4 w-full md:w-1/3 
        text-5xl md:text-8xl uppercase leading-[0.9] xl:leading-[0.8] z-[20]">

          <span className=" text-white opacity-25 pt-2 md:pt-0 px-2">
        {t('about.bigText1')}</span>
        </h3>
        </div>
        </div>

        {/* About us 2 */}
        <div className="m-0 h-[calc(100dvh)] md:h-[100vh] rounded-none  snap-center  flex flex-col md:flex-row items-center justify-center p-4 sm:p-4 md:p-16 lg:p-24 xl:p-32 gap-8">          
        <div className='w-full md:w-1/3'>
            <h3 className="font-sans text-white text-left md:text-right left-0 -translate-y-1/4 w-full md:w-1/3 
            text-5xl md:text-8xl uppercase leading-[0.9] xl:leading-[0.8] z-[20]">

<span className=" text-white opacity-25 pt-2 md:pt-0 px-2">
{t('about.bigText2')}</span>
            </h3>
            </div>
            <div className='w-full md:w-2/3'>

            <h2 className="font-sans text-enoOrange-200 uppercase leading-[1] sm:leading-[1] text-xl sm:text-2xl md:text-3xl">
            {t('about.tWhy2')}
            </h2>
            <p className="font-serif leading-tight text-white text-sm sm:text-base sm:leading-[1.25] mt-4">
            {t('about.why2')}
            </p>
            <p className="font-serif leading-tight text-white text-sm sm:text-base sm:leading-[1.25] mt-4">
            {t('about.why3')}</p> 
            
            <Link to="/contacts" 
            className="block mt-4 border-white border-solid  text-xl hover:text-enoOrange-200 text-white transition-colors duration-300" >
                 <FontAwesomeIcon icon={faContactCard} className=' mr-2'/>
                 {t('nav.contact')}
               </Link>
            </div>
        
        </div>

    </div>
    </>
}

export default AboutUs;