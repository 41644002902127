import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowLeft, faUsers, faEnvelope, faGlobe, faNewspaper, faBars, faMultiply, faCompactDisc } from '@fortawesome/free-solid-svg-icons'
import { faSpotify,  faAmazon, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import PressRelease from './pages/PressRelease';

import { useTranslation } from 'react-i18next';

import ImageGallery, { preloadImages } from "./components/ImageGallery"
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import LanguageSelector from './components/language-selector';
import { Link, Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import Privacy from './pages/Privacy';
import { HomeSEO } from './components/LangSeo';
import AlbumPage from './pages/Products';
import ContactForm from './components/Form';
import Dicono from './pages/Dicono';

export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const toggle = (newValue) => {
    setValue((prevValue) => {
      if (typeof newValue === 'boolean') {
        return newValue;
      }
      return !prevValue;
    });
  };

  return [value, toggle];
};


const FAQ = ({ question, answer, openFAQIndex, setOpenFAQIndex, faqIndex }) => {
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  const isOpen = openFAQIndex === faqIndex;

  const handleToggle = useCallback(() => {
    setOpenFAQIndex(prevIndex => (prevIndex === faqIndex ? null : faqIndex));
  }, [faqIndex, setOpenFAQIndex]);

  useEffect(() => {
    if (isOpen) {
      setHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setHeight('0px');
    }
  }, [isOpen]);

  return (
    <div className="faq-container border-b border-gray-300">
      <button
        className="faq-button w-full flex justify-between items-center py-1 sm:py-2 text-left text-white hover:text-zucsGreen-200 focus:outline-none"
        onClick={handleToggle}
      >
        <span
          className={`font-serif text-sm uppercase font-black sm:text-base md:text-lg ${
            isOpen ? 'text-enoOrange-200' : 'text-white'
          } hover:text-enoOrange-200`}
        >
          {question}
        </span>
        <FontAwesomeIcon 
          icon={isOpen ? faArrowDown : faArrowLeft} 
          className={`ml-4 transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} 
        />
      </button>
      <div
        ref={contentRef}
        style={{
          height: height,
          transition: 'height 0.3s ease-in-out',
          overflow: 'hidden',
        }}
      >
        <div className="pb-3 text-enoOrange-200 text-sm mt-1 sm:text-base leading-[1.1] sm:leading-tight font-serif">{answer}</div>
      </div>
    </div>
  );
};

// New component for collapsible text
const CollapsibleText = ({ text, maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState('auto');
  const contentRef = useRef(null);
  const [t] = useTranslation("global");

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isExpanded ? `${contentRef.current.scrollHeight}px` : `${maxLength}px`);
    }
  }, [isExpanded, maxLength]);

  // Ensure text is a string and split it into paragraphs
  const paragraphs = (typeof text === 'string' ? text : String(text)).split('/n');

  return (
    <div>
      <div
        ref={contentRef}
        style={{
          height: height,
          overflow: 'hidden',
          transition: 'height 0.3s ease-out'
        }}
      >
        {paragraphs.map((paragraph, index) => (
          <p key={index} className="font-serif text-white text-sm mt-4 sm:text-base sm:leading-[1.25]">
            {paragraph.trim()}
          </p>
        ))}
      </div>
      {contentRef.current && contentRef.current.scrollHeight > maxLength && (
        <button 
          onClick={toggleExpand} 
          className="ml-2 mt-2 text-enoOrange-400 hover:text-white transition-colors duration-300"
        >
          {isExpanded ? t('home.hide') : t('home.readAll')}
        </button>
      )}    </div>
  );
};


const getLocalizedPath = (path, language) => {
  if (language === 'it') {
    switch (path) {
      case '/about': return '/it/chi-siamo';
      case '/contacts': return '/it/contatti';
      case '/press': return '/it/press';
      case '/testimonials': return '/it/testimonials';
      case '/privacy': return '/it/privacy';
      default: return `/it${path}`;
    }
  } else if (language === 'en') {
    switch (path) {
      case '/it/chi-siamo': return '/about';
      case '/it/contatti': return '/contacts';
      case '/it/press': return '/press';
      case '/it/testimonials': return '/testimonials';
      case '/it/privacy': return '/privacy';
      default: return path.replace('/it', '');
    }
  }
  return path;
};

const NavLink = ({ to, children }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation('global');
  const { closeMenu } = React.useContext(AppContext);
  const navigate = useNavigate();
  const localizedTo = getLocalizedPath(to, i18n.language);
  const isActive = location.pathname === localizedTo;

  const handleClick = (e) => {
    e.preventDefault();
    if (location.pathname === localizedTo) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate(localizedTo);
    }
    closeMenu();
  };



  return (
    <Link
      to={localizedTo}
      className={`block hover:text-enoOrange-200 transition-colors duration-300 ${isActive ? 'text-enoOrange-200 underline' : ''}`}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

const AppContext = React.createContext(null);

function App() {
  const [t, i18n] = useTranslation('global');
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const location = useLocation();
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const lastScrollTop = useRef(0);
  const navbarRef = useRef(null);

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > lastScrollTop.current && scrollTop > 50) {
      setIsNavbarVisible(false);
    } else {
      setIsNavbarVisible(true);
    }
    lastScrollTop.current = scrollTop;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

    
  useEffect(() => {
    // Scroll to top when location changes
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const detectLanguage = () => {
      const path = window.location.pathname;
      if (path.startsWith('/it')) {
        i18n.changeLanguage('it');
      } else {
        i18n.changeLanguage('en');
      }
    };

    detectLanguage();
  }, [i18n, location]);

  useEffect(() => {
    const loadContent = async () => {
      setIsLoading(true);
      try {
        await preloadImages([
          '/photos/2.webp', '/photos/5.webp', '/photos/8.webp',
          '/photos/12.webp', '/photos/9.webp', '/photos/6.webp',
          '/photos/1.webp', '/photos/3.webp', '/photos/11.webp',
          '/photos/10.webp', '/photos/4.webp'
        ]);
      } catch (error) {
        console.error('Error loading content:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadContent();
  }, []);


  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  const contextValue = {
    closeMenu
  };

  return (
    <AppContext.Provider value={contextValue}>
      <nav 
        ref={navbarRef}
        className={`items-center text-white px-4 py-2 fixed w-full z-40 lg:bg-enoDark lg:bg-opacity-95 transition-transform 
          duration-300 ${isNavbarVisible ? 'translate-y-0' : '-translate-y-full'
        }`}
      >       <div className="flex justify-between items-center">
          <button onClick={toggleMenu} className="block lg:hidden z-[80]">
            <FontAwesomeIcon icon={isMenuOpen ? faMultiply : faBars} size="lg" />
          </button>
          <div className={`fixed inset-y-0 left-0 z-50 lg:bg-enoDark lg:bg-opacity-80 transform w-2/3 lg:hidden ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition duration-300 ease-in-out`}>
            {/* Mobile menu */}
            <ul className="flex flex-col h-full text-4xl pt-20 lg:bg-enoDark lg:bg-opacity-80 p-4 space-y-2">
            <a href='/' className='text-3xl cursor-pointer text-enoOrange-200'> <div className='w-48 flex items-center pb-1'>
              <img src={"/enotorrerec.png"} alt="Eno Torre" className="h-full w-auto" style={{width: '65%'}} />
              <img src={"/o.png"} alt="O" className="h-3/4 w-auto animate-spin mx-1 mt-2" style={{width: '15%'}} />
              <img src={"/rds.png"} alt="Records" className="h-full w-auto mt-2" style={{width: '20%'}} />
            </div> </a>
              <NavLink to="/">{t('nav.home')}</NavLink>
              <NavLink to="/massimo-chiarella-quartet"><FontAwesomeIcon className='mt-1' icon={faCompactDisc} /> {t('CD ETR001')}</NavLink>
              <NavLink to="/about">{t('nav.who')}</NavLink>
              <NavLink to="/press">{t('nav.press')}</NavLink>
              <NavLink to="/testimonials">{t('nav.dicono')}</NavLink>
              <NavLink to="/contacts">{t('nav.contact')}</NavLink>
            </ul>
          </div>
          <div className="hidden lg:flex items-center w-full">
            {/* Desktop menu */}
            <a href='/' className='text-3xl cursor-pointer text-enoOrange-200'> <div className='w-48 flex items-center pb-1'>
              <img src={"/enotorrerec.png"} alt="Eno Torre" className="h-full w-auto" style={{width: '65%'}} />
              <img src={"/o.png"} alt="O" className="h-3/4 w-auto animate-spin mx-1 mt-2" style={{width: '15%'}} />
              <img src={"/rds.png"} alt="Records" className="h-full w-auto mt-2" style={{width: '20%'}} />
            </div> </a>
            <ul className="flex space-x-4 ml-10">
              <NavLink to="/">{t('nav.home')}</NavLink>
              
              <div className="relative z-40">
                <button
                  className="focus:outline-none cursor-default flex items-center gap-0"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  Products
                </button>
                <div
                  className={`absolute top-full bg-enoDark bg-opacity-70 w-40 left-0 border border-none rounded shadow 
                    p-2 z-40 transition-all duration-500 ease-in-out transform 
                    ${hovered ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-2 pointer-events-none"}`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavLink to="/massimo-chiarella-quartet"><FontAwesomeIcon className='mt-1' icon={faCompactDisc} /> {t('CD ETR001')}</NavLink>
                </div>
              </div>

              <NavLink to="/about">{t('nav.who')}</NavLink>
              <NavLink to="/press">{t('nav.press')}</NavLink>
              <NavLink to="/testimonials">{t('nav.dicono')}</NavLink>
              <NavLink to="/contacts">{t('nav.contact')}</NavLink>
            </ul>
          </div>
          <div className="flex items-center">
            <LanguageSelector />
          </div>
        </div>
      </nav>
      {isMenuOpen && (
        <div
          className="fixed inset-0  bg-black bg-opacity-80 w-4/5 z-30"
          onClick={closeMenu}
        ></div>
      )}
      {isLoading ? (
        <div className="flex justify-center bg-enoDark items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-zucsGreen-200"></div>
        </div>
      ) : (
        <main className="w-full bg-enoOrange-200 overflow-x-hidden">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contacts" element={<Contact />} />
            <Route path="/press" element={<PressRelease />} />
            <Route path="/testimonials" element={<Dicono />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/massimo-chiarella-quartet" element={<AlbumPage />} />

            {/* Italian routes */}
            <Route path="/it" element={<Home />} />
            <Route path="/it/chi-siamo" element={<AboutUs />} />
            <Route path="/it/contatti" element={<Contact />} />
            <Route path="/it/press" element={<PressRelease />} />
            <Route path="/it/testimonials" element={<Dicono />} />
            <Route path="/it/privacy" element={<Privacy />} />
            <Route path="/it/massimo-chiarella-quartet" element={<AlbumPage />} />

            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <a
            target='_blank'
            href="https://wa.me/393886064901?text=I'm%20interested%20in%20purchasing%20the%20CD%20from%20Enotorrerecords.com"
            className="fixed transition-colors duration-700 h-[36px] w-[36px] sm:h-[46px] sm:w-[46px] content-center text-center aspect-square 
            cursor-pointer left-3 bottom-3 z-50 bg-[#1c1c1c] text-enoOrange-200 hover:text-green-600 text-2xl rounded-full"
          >
            <FontAwesomeIcon className='mt-[2px] w-[34px] h-[34px] sm:w-11 sm:h-11' icon={faWhatsapp} />
          </a>
        </main>
      )}
    </AppContext.Provider>
  );
}

export default App;

function Home() {
  const [openFAQIndex, setOpenFAQIndex] = useState(null);
  const [t] = useTranslation("global");

  const contactContainerRef = useRef(null);

  const handleScroll = (e) => {
    e.preventDefault(); // Prevent default link behavior
    const contactContainer = contactContainerRef.current;
    if (contactContainer) {
      contactContainer.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const faqs = [
    {
        question: t('home.q1'),
        answer: t('home.a1')
    },
    {
        question: t('home.q2'),
        answer: t('home.a2')
    },
    {
        question: t('home.q3'),
        answer: t('home.a3')
    },
    {
        question: t('home.q4'),
        answer: t('home.a4')
    },
    {
        question: t('home.q5'),
        answer: t('home.a5')
    },
    {
        question: t('home.q6'),
        answer: t('home.a6')
    },
    {
        question: t('home.q7'),
        answer: t('home.a7')
    },
    {
        question: t('home.q8'),
        answer: t('home.a8')
    }
]


  return <>
        <HomeSEO />
                 
    {/* Hero div */}
    <div className="m-0 h-[calc(100dvh)]  rounded-none md:rounded-lg snap-start bg-enoDark flex flex-col md:flex-row 
    items-center justify-center p-4 sm:p-4 md:p-8 lg:p-10 xl:p-10 2xl:p-40 gap-8">
      <div className="w-full md:w-1/2 lg:w-3/5">
        <ImageGallery />
      </div>
      <div className="w-full md:w-1/2 lg:w-3/5 flex flex-col justify-center items-start">
      
      
      <a href='/' className='text-3xl mb-4 lg:mb-10 cursor-pointer text-enoOrange-200'> <div className='w-80 flex items-center pb-1'>
              <img src={"/enotorrerec.png"} alt="Eno Torre" className="h-full w-auto" style={{width: '65%'}} />
              <img src={"/o.png"} alt="O" className="h-3/4 w-auto hover:animate-spin mx-1 mt-2" style={{width: '15%'}} />
              <img src={"/rds.png"} alt="Records" className="h-full w-auto mt-2" style={{width: '20%'}} />
            </div> </a>
        <h1 className="font-sans text-enoOrange-200 leading-[1] sm:leading-[0.8] lg:leading-[0.8] md:leading-[0.8]  xl:leading-[0.8] 2xl:leading-[0.8]  text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-8xl uppercase">
        {t('home.h1')}
        </h1>
        <p className="font-serif text-white w-full lg:w-4/5 text-sm mt-2">
        {t('home.p')}</p>
        <div className="mt-6 flex flex-row items-center gap-4">
          <a
          onClick={handleScroll}
            href="#"
            className="text-white p-2 text-base px-6 border-2  hover:border-enoOrange-200 border-white hover:rounded-lg hover:bg-enoOrange-200 
            hover:-rotate-1 uppercase leading-[1] transition-rotate duration-300 ease-in-out text-center w-full sm:w-auto"
          >
            {t('home.buy')}
          </a>
         
        </div>
    
      </div>
    </div>

      {/* Dedicated */}
      <div className="h-[calc(100dvh)] rounded-none md:rounded-lg relative snap-start flex flex-col md:flex-row items-center justify-center p-4 sm:p-4 md:p-16 lg:p-24 xl:p-32 gap-8">
        <h3 className="font-sans text-white text-right md:text-right left-0 -translate-y-1/5 
        w-full md:w-1/4 xl:w-1/2 leading-[0.9] xl:leading-[0.8] text-5xl md:text-8xl uppercase  z-[10]">
        <span className=" text-white opacity-25 pt-2 md:pt-0 px-2">
        {t('home.bigText1')}</span>
        </h3>

        <div className='w-full md:w-4/5 xl:w-3/5 z-10'>

          <h2 className="font-sans uppercase leading-[1] sm:leading-[1] text-enoOrange-400 text-xl sm:text-2xl md:text-3xl">
          {t('home.title1')}</h2>
      
         <CollapsibleText className="-mt-6" text={t('home.p1') + '/n' + t('home.title2') + '/n' + t('home.p2') } maxLength={95} />
         <Link to="/about" className="block mt-5 text-xl text-white hover:text-enoOrange-400 transition-colors duration-300" >
                  <FontAwesomeIcon icon={faUsers} className="mr-2" />
                  {t('nav.who')}
                </Link>
        </div>
      </div>




    {/* Where to listen */}
    <div className="bg-cover m-0  h-[calc(100dvh)] rounded-none snap-start bg-center bg-[#202020] bg-hero-pattern flex flex-col justify-center items-center p-4 sm:p-4 md:p-12 lg:p-16">

      <div className="w-full max-w-6xl flex flex-col-reverse lg:flex-row items-end justify-center gap-5">
      <div className='w-full lg:w-3/5'>
      <h2 className="font-sans text-enoOrange-200 text-xl sm:text-2xl md:text-3xl">
        {t('home.title4')}
        </h2>
        <p className="font-serif text-white text-sm sm:text-base sm:leading-[1.25]">
        {t('home.p4')}</p>
        <h2 className="font-sans text-enoOrange-200 text-xl mt-5 sm:text-2xl md:text-3xl">
      {t('home.bigText2')}
      </h2>
      <div className="flex flex-row gap-1">
      <a
        href="https://open.spotify.com/album/3ltDiQRWmEDfBfZQvKQF6R"
        target="_blank"
        rel="noopener noreferrer"
        className="flex hover:bg-[#404040] hover:rounded-lg flex-col items-center hover:scale-110 transition-transform duration-300 p-4"
      >
        <FontAwesomeIcon icon={faSpotify} className="text-4xl sm:text-5xl text-white mb-4" />
        <span className="text-white hidden md:block">Spotify</span>
      </a>
    
      <a
        href="#"
        target="https://music.amazon.it/artists/B0D1CXBC98/massimo-chiarella-quartet?marketplaceId=APJ6JRA9NG5V4&musicTerritory=IT&ref=dm_sh_TZu67zFPZ6MNZkN3kJKU9cEPX"
        rel="noopener noreferrer"
        className="flex flex-col hover:bg-[#404040] hover:rounded-lg items-center hover:scale-110 transition-transform duration-300 p-4"
      >
        <FontAwesomeIcon icon={faAmazon} className="text-4xl sm:text-5xl text-white mb-4" />
        <span className="text-white hidden md:block">Amazon Music</span>
      </a>
      <a
        href="#"
        target="https://music.apple.com/us/album/live-at-enotecalatorre-feat-danilo-memoli-david-boato/1740782313"
        rel="noopener noreferrer"
        className="flex flex-col hover:bg-[#404040] hover:rounded-lg items-center hover:scale-110 transition-transform duration-300 p-4"
      >
        <FontAwesomeIcon icon={faApple} className="text-4xl sm:text-5xl text-white mb-4" />
        <span className="text-white hidden md:block">Apple Music</span>
      </a>

      <a
        href="https://www.qobuz.com/it-it/album/live-at-enotecalatorre-massimo-chiarella-quartet/gmi6s2xg6iwma"
        target="_blank"
        rel="noopener noreferrer"
        className="flex hover:bg-[#404040] hover:rounded-lg flex-col items-center hover:scale-110 transition-transform duration-300 p-4"
      >
        <img src='/qobuz_logo_light.svg' alt='logo-qobuz' className="w-36 text-white mb-4" />
      </a>
    </div>
    </div>
    <div className="flex flex-col w-full lg:w-1/3 items-start ">
    
    
    </div>
    </div>
  </div>

    {/* Partners */}
    <div className="bg-[#1a1a19] relative snap-center flex flex-col xl:flex-row 
      items-center justify-center p-4 sm:p-4 md:p-10 xl:p-10 gap-8">
        <h3 className="font-sans text-white text-right md:text-right left-0 -translate-y-1/5 
        w-full xl:w-1/2 leading-[0.9] xl:leading-[0.8] text-5xl md:text-8xl uppercase  z-[10]">
        <span className=" text-white opacity-25 pt-2 md:pt-0 px-2">
        {t('I NOSTRI PARTNERS')}</span>
        </h3>

        <div className='w-full flex lg:flex-row flex-col items-end xl:w-4/5 gap-4 z-20 py-10'>

        
          <a
            href="https://www.dapievehifi.it/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex hover:bg-[#fff]  xl:h-40 justify-center w-full lg:w-1/3 bg-[#ccc] rounded-lg flex-col items-center hover:scale-110 transition-transform  duration-300 p-4"
             >
            <img src='/dapieve.webp' alt='logo da pieve' className="w-36 text-white mb-4" />
        </a>

        <a
            href="https://www.omegaudioconcepts.com/it/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex hover:bg-[#fff]  xl:h-40 justify-center w-full lg:w-1/3 bg-[#ccc] rounded-lg flex-col items-center hover:scale-110 transition-transform  duration-300 p-4"
             >
            <img src='/omega.webp'alt='logo omega' className="w-36 text-white mb-4" />
        </a>
        <a
            href="https://www.musicatelli.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex hover:bg-[#fff]  xl:h-40 justify-center w-full lg:w-1/3 bg-[#ccc] rounded-lg flex-col items-center hover:scale-110 transition-transform  duration-300 p-4"
             >
            <img src='/Musicatelli.webp' alt='logo musicatelli' className="w-36 text-white mb-4" />
        </a>

        </div>
      </div>

      

    
{/* Mission */}
<div className="m-0 md:m-2 h-[calc(100dvh)] md:h-[98vh] rounded-none md:rounded-lg  snap-start  flex flex-col md:flex-row 
items-center justify-center p-4 sm:p-4 md:p-16 lg:p-16 xl:p-20 gap-8">          
<h3 className="font-sans text-white text-left md:text-right left-0 -translate-y-1/4 w-full md:w-1/3 
text-5xl leading-[0.9] xl:leading-[0.8]  md:text-8xl uppercase  z-[10]">
<span className=" text-white opacity-25 pt-2 md:pt-0 px-2">
{t('home.bigText3')}</span>
</h3>
  <div className='w-full md:w-2/3 xl:w-2/3 z-20'>
  <h2 className="font-sans text-enoOrange-400 leading-[1] sm:leading-[1] uppercase text-xl sm:text-2xl md:text-3xl">
  {t('home.title5')}
    </h2>
    
    <CollapsibleText className="-mt-6" text={t('home.p5') + '/n' + t('home.title6') + '/n' + t('home.p6') } maxLength={95} />
    <Link to="/press" className="block mt-5 text-xl text-white hover:text-enoOrange-400 transition-colors duration-300" >
                  <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
                  {t('nav.press')}
                </Link>
  </div>
</div>




{/* FAQ div */}
<div className="flex flex-col-reverse h-[calc(100dvh)] p-0 sm:p-0 md:p-16 
lg:p-24 xl:p-32 relative snap-start bg-footer-texture bg-fixed bg-cover bg-[#202020]">
<div
  className='z-10 bg-[#303030fb] justify-center content-start right-0 top-0 none 
  md:absolute duration-500 w-full xl:w-3/5 p-4 ease-in-out h-[100vh]'
  
>
  
  <div className='h-auto md:h-[calc(95.5dvh)] md:px-5 mt-8 md:mt-10'>
  <h2 className="font-sans text-zucsGreen-200 text-xl sm:text-2xl md:text-3xl">
  <span className="text-enoOrange-200 pt-2 md:pt-0 ">{t('home.faqT')}</span>
  </h2>
    {faqs.map((faq, index) => (
          <FAQ
            key={index}
            faqIndex={index}
            question={faq.question}
            answer={faq.answer}
            openFAQIndex={openFAQIndex}
            setOpenFAQIndex={setOpenFAQIndex}
          />
        ))}
</div>
</div>

</div>

 {/* CTA DISK */}
 <div className="m-0 h-auto  rounded-none md:rounded-lg snap-start bg-enoDark flex flex-col md:flex-row 
    items-center justify-center px-4 sm:px-4 md:px-8 lg:px-10 xl:px-10 2xl:px-40 gap-8 pt-40 pb-20 lg:pb-2">
      <div className="w-full md:w-1/2 lg:w-3/5">
      <img src='/fullbackcover800px.webp' alt='copertina disco edizione 1 enotorrerecords' className='w-full hover:scale-110 hover:shadow-3xl transition-transform duration-300 ease-in-out
        hover:shadow-white rounded-lg hover:rotate-2'></img>
      </div>
      <div className="w-full md:w-1/2 lg:w-3/5 flex flex-col justify-center items-start">
      
   
        <h2 className="font-sans text-enoOrange-200 leading-[1] sm:leading-[0.8] lg:leading-[0.8] md:leading-[0.8]  xl:leading-[0.8] 2xl:leading-[0.8]  text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-8xl uppercase">
        {t('home.tCTA')}
        </h2>
        <p className="font-serif text-white w-full lg:w-4/5 text-sm mt-2">
        {t('home.pCTA')}</p>
        <div className="mt-4 text-white hover:text-enoOrange-200 cursor-pointer p-2 text-base px-6 border-2  hover:border-enoOrange-200 border-white hover:rounded-lg hover:bg-white 
            hover:-rotate-1 uppercase leading-[1] transition-rotate duration-300 ease-in-out text-center w-full sm:w-auto">
        <NavLink to="/massimo-chiarella-quartet"><FontAwesomeIcon className='mr-2' icon={faCompactDisc} />{t('home.buyCTA')}</NavLink>

         
        </div>
    
      </div>
    </div>

    {/* Form div */}
    <div ref={contactContainerRef}  id='contact' className="px-4 snap-start h-auto bg-enoDark md:px-12 lg:px-1 xl:px-24 py-5">


        <ContactForm />

      </div>

{/* Footer */}
<footer className=" bg-[#303030] h-[50vh] p-6 text-white place-content-center snap-end gap-4 sm:p-6 font-serif text-center md:p-12 lg:p-16">
          <div className="container mx-auto flex flex-col md:flex-row justify-center items-center">
            <div className="flex flex-col md:flex-row items-center">
              <div className='mt-4 md:mt-0  text-sm'>
                
              <img src="/enoteca_long.png" alt="Footer Logo jazz series" className="w-2/3 align-middle m-auto mb-4" />
                <p>L'ENOTORRE S.A.S. DI ANTONIO POSTIGLIONE & C</p>
                <p>VIA DI MEZZO 2, 33097 Spilimbergo PN ITALIA</p>
                <p>P.IVA 00632760930 | info@enotorrerecords.com</p>
              </div>
                </div>
          
            
          </div>
          <div className="flex p-4 md:mt-0 flex-col mt-2 justify-center gap-2 items-center">
          <p className="text-sm mt-5 text-white">
                © 2024 LIVE<span className="text-zucsGreen-200"><strong>AT</strong></span>
                ENOTECALATORRE. {t('home.rights')}
              </p>
            <Link to="/privacy" className="hover:text-enoOrange-200 transition-colors duration-300">
                          {t('Privacy Policy')}
                        </Link>
              
            </div>
          
        </footer>


  </>
}