import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export function HomeSEO() {
  const { t, i18n } = useTranslation("global");
  const lang = i18n.language;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{t("seo.home.title")}</title>
      <meta name="description" content={t("seo.home.description")} />
      <meta name="keywords" content={t("seo.home.keywords")} />
      <link rel="alternate" hreflang="it" href="https://www.enotorrerecords.com/it" />
      <link rel="alternate" hreflang="en" href="https://www.enotorrerecords.com" />
      <link rel="canonical" href={`https://www.enotorrerecords.com${lang === 'it' ? '/it' : ''}`} />
    </Helmet>
  );
}

export function AboutSEO() {
  const { t, i18n } = useTranslation("global");
  const lang = i18n.language;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{t("seo.about.title")}</title>
      <meta name="description" content={t("seo.about.description")} />
      <meta name="keywords" content={t("seo.about.keywords")} />
      <link rel="alternate" hreflang="it" href="https://www.enotorrerecords.com/it/about" />
      <link rel="alternate" hreflang="en" href="https://www.enotorrerecords.com/about" />
      <link rel="canonical" href={`https://www.enotorrerecords.com${lang === 'it' ? '/it/chi-siamo' : '/about'}`} />
    </Helmet>
  );
}

export function ProductSEO() {
  const { t, i18n } = useTranslation("global");
  const lang = i18n.language;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{t("seo.product.title")}</title>
      <meta name="description" content={t("seo.product.description")} />
      <meta name="keywords" content={t("seo.product.keywords")} />
      <link rel="alternate" hreflang="it" href="https://www.enotorrerecords.com/it/massimo-chiarella-quartet" />
      <link rel="alternate" hreflang="en" href="https://www.enotorrerecords.com/massimo-chiarella-quartet" />
      <link rel="canonical" href={`https://www.enotorrerecords.com${lang === 'it' ? '/it' : ''}/massimo-chiarella-quartet`} />
    </Helmet>
  );
}