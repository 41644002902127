import React, {  useEffect, useRef, useState, useTransition } from 'react'
import * as THREE from 'three'
import { useGLTF, useAnimations, useTexture, Html, Clone, Float  } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export function Model({enableEffects, setEnableEffects, ...props}) {

  const [ i18n] = useTransition('global');
  
  const { t } = useTranslation("global");
  const language = i18n.language;
 // Function to get localized paths
const getLocalizedPath = (path, language) => {
  if (language === 'it') {
    switch (path) {
      case '/about': return '/it/chi-siamo';
      case '/contacts': return '/it/contatti';
      case '/press': return '/it/press';
      case '/privacy': return '/it/privacy';
      default: return `/it${path}`;
    }
  } else if (language === 'en') {
    switch (path) {
      case '/it/chi-siamo': return '/about';
      case '/it/contatti': return '/contacts';
      case '/it/press': return '/press';
      case '/it/privacy': return '/privacy';
      default: return path.replace('/it', '');
    }
  }
  return path;
};
  
const texture2 = useTexture(getLocalizedPath('/enotorredisk-final.png', language));
const texture1 = useTexture(getLocalizedPath('/enotorredisk-final-2.png', language));
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [currentTexture, setCurrentTexture] = useState(0)
  const progressRef = useRef(0)
  
  // Flip textures
  texture1.flipY = false
  texture2.flipY = false
  texture1.colorSpace = THREE.SRGBColorSpace
  texture2.colorSpace = THREE.SRGBColorSpace

  const shaderMaterial = useRef(
    new THREE.ShaderMaterial({
      uniforms: {
        texture1: { value: texture1 },
        texture2: { value: texture2 },
        progress: { value: 0 },
        noiseScale: { value: 0.05 },
        noiseStrength: { value: 0.05 }
      },
      vertexShader: `
        #include <skinning_pars_vertex>
        varying vec2 vUv;
        void main() {
          vUv = uv;
          #include <skinbase_vertex>
          #include <begin_vertex>
          #include <skinning_vertex>
          #include <project_vertex>
        }
      `,
      fragmentShader: `
        uniform sampler2D texture1;
        uniform sampler2D texture2;
        uniform float progress;
        uniform float noiseScale;
        uniform float noiseStrength;
        varying vec2 vUv;

        vec3 permute(vec3 x) { return mod(((x*34.0)+1.0)*x, 289.0); }

        float snoise(vec2 v) {
          const vec4 C = vec4(0.211324865405187, 0.366025403784439,
                   -0.577350269189626, 0.024390243902439);
          vec2 i  = floor(v + dot(v, C.yy) );
          vec2 x0 = v -   i + dot(i, C.xx);
          vec2 i1;
          i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
          vec4 x12 = x0.xyxy + C.xxzz;
          x12.xy -= i1;
          i = mod(i, 289.0);
          vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
          + i.x + vec3(0.0, i1.x, 1.0 ));
          vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy),
            dot(x12.zw,x12.zw)), 0.0);
          m = m*m ;
          m = m*m ;
          vec3 x = 2.0 * fract(p * C.www) - 1.0;
          vec3 h = abs(x) - 0.5;
          vec3 ox = floor(x + 0.5);
          vec3 a0 = x - ox;
          m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
          vec3 g;
          g.x  = a0.x  * x0.x  + h.x  * x0.y;
          g.yz = a0.yz * x12.xz + h.yz * x12.yw;
          return 130.0 * dot(m, g);
        }

        void main() {
          vec4 color1 = texture2D(texture1, vUv);
          vec4 color2 = texture2D(texture2, vUv);
          
          float noiseValue = snoise(vUv / noiseScale) * noiseStrength;
          float threshold = progress + noiseValue;
          
          gl_FragColor = vUv.x < threshold ? color1 : color2;
        }
      `,
      side: THREE.DoubleSide
    })
  );

  useFrame((state, delta) => {
    if (isTransitioning) {
      const targetProgress = currentTexture === 0 ? 1 : 0;
      const speed = 0.5; // Adjust this value to control transition speed (progress per second)

      // Calculate the step based on delta time
      const step = speed * delta;

      progressRef.current += currentTexture === 0 ? step : -step;
      progressRef.current = THREE.MathUtils.clamp(progressRef.current, 0, 1);

      shaderMaterial.current.uniforms.progress.value = progressRef.current;

      if (Math.abs(progressRef.current - targetProgress) < 0.0001) {
        setIsTransitioning(false);
        setCurrentTexture(1 - currentTexture);
        progressRef.current = targetProgress;
      }
    }
  });

  const handleToggle = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
    }
  }

  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/Official-enotorre-disk2-transformed.glb')

  const { actions } = useAnimations(animations, group)

  
  const booklet = useGLTF(getLocalizedPath('/stillBooklet.glb', language))
  const onlyDisk = useGLTF(getLocalizedPath('/onlyDisk.glb', language))

const [playForward, setPlayForward] = useState(true)

const handleDiskClick = () => {
  const action = actions.Action
  action.setLoop(THREE.LoopOnce, 1)
  action.clampWhenFinished = true

  const timeScale = playForward ? 1 : -1
  action.timeScale = timeScale * 7
  action.paused = false
  action.play()
  setPlayForward(!playForward)
}


const diskPlastic = useGLTF(getLocalizedPath('/diskPlastic.glb', language))
  const plastic = diskPlastic.materials.plastic

  plastic.roughness = 0.15
  plastic.side = 1
  plastic.ior = 3
  plastic.thickness = 0.01

  const onlydiskRef = useRef()

  useEffect(() => {
    if (onlyDisk.scene) {
      onlyDisk.scene.traverse((child) => {
        if (child.isMesh) {
          child.geometry.center() // Ensure the geometry is centered
        }
      })
    }
  }, [onlyDisk])

  const [isPlay, setIsPlay] = useState(false)
  const audioRef = useRef(new Audio("/Waltz _For_Ruth_Massimo_Chiarella_Quartet.mp3"));

  const handlePlay = () => {
    if (isPlay) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlay(!isPlay);
  }


  useFrame(() => {
    if (isPlay) {
      onlydiskRef.current.rotation.y += 0.01 // Adjust the rotation speed as needed
    }
  })


 
  
  return <group ref={group} {...props} dispose={null}>
    
    <Float floatIntensity={0.01} floatingRange={[0, 1]}>
    <Html
        transform
        occlude
        material={<meshPhysicalMaterial side={THREE.DoubleSide} opacity={0.1} />}
        position={[0, -2, 0.5]}
        rotation={[-0.3, 0, 0]}
        distanceFactor={5}
      >  
      <button className='button' onClick={handlePlay}><FontAwesomeIcon icon={isPlay ? faPause : faPlay} /></button>
      <button className='button' onClick={handleToggle}>{t('model.preview')}</button>
      <button className='button prevent-select' onClick={handleDiskClick}>{playForward ? t('model.open') : t('model.close') }</button>


      <label class="container"> {t('model.effect')}
        <input type="checkbox"  checked={enableEffects}
            onChange={(e) => setEnableEffects(e.target.checked)} ></input>
        <span class="checkmark"></span>
      </label>
    </Html>
      <group name="Scene" scale={5} rotation={[0, Math.PI * 0.5, 1.1]}>
        <group name="Armature">
          <primitive object={nodes.Bone} />
        </group>
        <group name="Plane001">
          <skinnedMesh name="Plane001_1" geometry={nodes.Plane001_1.geometry}  material={shaderMaterial.current} skeleton={nodes.Plane001_1.skeleton} />
          <skinnedMesh name="Plane001_2" geometry={nodes.Plane001_2.geometry}  material={materials.paper} skeleton={nodes.Plane001_2.skeleton} />
          <primitive object={diskPlastic.scene} />
          <group position={[0, 0.015, +0.31]}>
            <mesh geometry={onlyDisk.nodes.Circle.children[0].geometry} material={onlyDisk.materials.diskPlastic} />
            <mesh geometry={onlyDisk.nodes.Circle.children[1].geometry} material={onlyDisk.materials.disk} />
            <mesh geometry={onlyDisk.nodes.Circle.children[2].geometry} material={shaderMaterial.current} />
          </group>
          
          <group ref={onlydiskRef} position={[0, -0.05, -0.1]}>
            <mesh geometry={onlyDisk.nodes.Circle.children[0].geometry} material={onlyDisk.materials.diskPlastic} />
            <mesh geometry={onlyDisk.nodes.Circle.children[1].geometry} material={onlyDisk.materials.disk} />
            <mesh geometry={onlyDisk.nodes.Circle.children[2].geometry} material={shaderMaterial.current} />
          </group>
          <Clone object={booklet.scene} inject={({ material: shaderMaterial.current })} position={[0.3, -0.15, 0.5]} />

        </group>
      </group>
    </Float>
   
  </group>
}

useGLTF.preload('/Official-enotorre-disk2-transformed.glb')

